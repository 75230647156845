import { useState } from 'react';
import { ethers } from 'ethers';
import './App.css';
import contractABI from './assets/contract.abi.json';
import bytecode from './assets/contract.bytecode.js';


function App() {
  const [isConnected, setConnected] = useState(false);
  const [account, setAccount] = useState('');
  const [address, setContractAddress] = useState('');

  async function tryConnect () {
    if (typeof window.ethereum === 'undefined') {
      alert('Please install MetaMask to use this dApp!');
      return;
    }

    try {
      const ethProvider = new ethers.providers.Web3Provider(window.ethereum);

      await ethProvider.send('eth_requestAccounts', []);
      const signer = await ethProvider.getSigner();

      const signerAddress = await signer.getAddress();
      setAccount(signerAddress);
      setConnected (true);
    } catch (e) {
      console.log(e);
    }
  };

  async function tryDeploy () {
    const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await ethProvider.getSigner();

    console.log("ABI : ", contractABI);
    console.log("ByteCode : ", bytecode);

    // create a new Contract Factory
    const factory = new ethers.ContractFactory(contractABI, bytecode, signer);

    // deploy the contract
    factory.deploy("0x93d938c5cbca6fdecf68eda08c931bf244e23838", "0xB8719f33A832B6652e58B061C49318Cf4E641C79")
      .then(contract => {
        setContractAddress(contract.address);
        console.log("Contract : ", contract);
        console.log("Deployed at address: ", contract.address);
      });
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>The Deployer</h1>
        { !isConnected && <button onClick={tryConnect}>Connect</button> }
        { isConnected && <button onClick={tryDeploy}>Deploy</button> }
        <div>Account : {account}</div>
        <div>Contract : {address}</div>
      </header>
    </div>
  );
}

export default App;
